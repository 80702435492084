import React, { useState } from "react";
import images from "../../images";
import { useNavigate } from "react-router-dom";
import OtpModal from "../../components/OtpModal/OtpModal";

function SignUp() {
  const navigate = useNavigate();
  const [showOtpModal, setShowOtpModal] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setShowOtpModal(true);
  };
  return (
    <>
      <div className="text-center tracking-wide">
        <div className="text-[2rem] text-black">Create Account</div>
        <div className="text-black opacity-[56%] font-poppins-500 pb-5">
          Please Enter Your Details
        </div>
        <div className="w-full diminishing-line"></div>
        <div className="mt-10">
          <form>
            <div className="input-container mt-4">
              <div className="p-3 bg-[#B7BBC11F] bg-opacity-[12%]">
                <img className="w-[20px]" src={images.phone} alt="name" />
              </div>
              <div className="flex-1 ">
                <input
                  className="input-text"
                  type="input"
                  placeholder="Phone Number"
                />
              </div>
            </div>
            <div className="text-start mt-5">
              <input type="checkbox" className="scale-150" />
              <div className="font-poppinsMedium text-[0.8rem] text-[#676B89] mt-2">
                By continuing, you accept to our{" "}
                <span className="text-black">Terms and Conditions </span> and{" "}
                <span className="text-black">Privacy Policy</span>
              </div>
            </div>
            <button
              onClick={handleSubmit}
              className="continue mt-5 bg-[#217229] text-white w-full p-4 rounded-xl font-inter font-semibold text-[1.15rem]"
            >
              Continue
            </button>
          </form>
          <div className="mt-10 text-[#293286] font-inter font-semibold text-[1rem]">
            Already have an Account?{" "}
            <span
              onClick={() => navigate("/")}
              className="cursor-pointer underline"
            >
              Login
            </span>{" "}
          </div>
        </div>
      </div>
      {showOtpModal && <OtpModal to={"/"} setShowOtpModal={setShowOtpModal} />}
    </>
  );
}

export default SignUp;
