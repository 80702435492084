import React from "react";
import images from "../../images";
import { useDispatch } from "react-redux";
import { open_product_description } from "../../redux/productDescriptionSlice";

function ProductCard({ showOffer = false }) {
  const dispatch = useDispatch();
  const openDescription = () => {
    dispatch(open_product_description());
  };
  return (
    <div
      onClick={openDescription}
      className="bg-white rounded-xl pt-1 cursor-pointer"
    >
      <div className="px-1">
        <div className="flex items-center justify-center rounded-xl bg-[#EBEEF2] p-2 border relative">
          <img src={images.sampleLays} className="w-[50%]" />
          {/* offer sticker */}
          {showOffer && (
            <div className="font-poppinsLight text-[0.88rem] absolute bottom-1 right-1 bg-white px-3 py-1 rounded-3xl">
              12% OFF
            </div>
          )}
        </div>
        <div className="font-poppinsMedium text-[0.825rem] cutoff-text mx-3 mt-2">
          Oman Chili Potato Chips Spicy Indian Flavour fidji
          fjdsijfidsjfisdifsdii
        </div>
        <div className=" font-poppinsMedium text-[0.825rem] underline underline-[#217229] underline-offset-4 text-[#217229] mx-3 mt-2 cutoff-text-one">
          Zoom Supermarket
        </div>
        <div className="flex items-center gap-5 mx-3 mt-2">
          <div className="font-poppinsMedium text-[#00000094] text-[0.825rem]">
            48g
          </div>
          <div className="flex items-center gap-1">
            <div>
              <img src={images.star} className="w-[13px]" />
            </div>
            <div className="font-poppinsMedium text-[0.825rem]">4.9</div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between p-3 px-3  rounded-b-xl text-black">
        <div className="font-poppinsSemiBold text-[0.825rem] ">20 AED</div>
        <button
          onClick={(e) => {
            return e.stopPropagation();
          }}
          className="text-[#217229] border border-[#217229] px-3 py-1 rounded-lg text-[0.85rem] font-poppinsMedium"
        >
          ADD
        </button>
        {/* <div className="flex items-center gap-3">
          <button className="text-[1rem]"> - </button>
          <div> 0 </div>
          <button className="text-[1rem]"> + </button>
        </div> */}
      </div>
    </div>
  );
}

export default ProductCard;
