import React, { useEffect, useRef, useState } from "react";

// import ProductCard from "./ProductCard";
import images from "../images";

import CartItemStrip from "./CartItemStrip/CartItemStrip";
import { useDispatch } from "react-redux";
import { close_cart } from "../redux/cartDisplaySlice";
import { open_checkout } from "../redux/checkoutDisplaySlice";

function CartWindow({ handleModal, a, setIsCartOpen }) {
  const dispatch = useDispatch();
  const cartItems = Array.from({ length: 50 }, (_, idx) => ({
    id: 1,
    name: "Lay's Spanish Tomato Tango",
    size: "20g",
    quantity: 2,
    price: 20,
    image: images.suggestedproducts, // Add proper image path
  }));

  const cartWindowRef = useRef(null); // Ref for cart window

  const handleCheckout = () => {
    dispatch(open_checkout());
    dispatch(close_cart());
  };

  // Timer countdown

  const formatTime = (seconds) => {
    const min = Math.floor(seconds / 60);
    const sec = seconds % 60;
    return `${min}:${sec < 10 ? `0${sec}` : sec}`; // Ensure double digits for seconds
  };

  // Slide-in animation when the component mounts
  useEffect(() => {
    setTimeout(() => {
      cartWindowRef.current.classList.remove("translate-x-full");
    }, 10); // Start the slide-in animation after mounting
  }, []);

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-end z-50">
      {/* Cart Window */}
      <div
        ref={cartWindowRef}
        className="bg-gray-100 w-[28rem] h-full shadow-lg p-6 relative transform transition-transform duration-300 translate-x-full" // Initially off-screen
      >
        {/* Close Button */}
        <button
          className="absolute top-4 right-4 text-gray-600 text-2xl font-bold"
          onClick={() => dispatch(close_cart())}
        >
          &times;
        </button>

        {/* Delivery and Timer */}
        <div className="flex items-center mb-2">
          {/* Timer Icon */}
          {/* <span className="text-xl text-gray-600 mr-2">&#9202;</span> */}

          {/* Countdown and Shipment Details */}
          {/* <div className="flex flex-col">
            <p className="text-[0.7rem] font-bold text-gray-500">
              Delivery in {formatTime(timeRemaining)}
            </p>
            <p className="text-[0.6rem] text-gray-400">
              Shipment of {cartItems.length} items
            </p>
          </div> */}
        </div>

        {/* Delivery Estimate */}
        <div className="flex items-center justify-center mb-7">
          <div className="text-center border rounded-full shadow-lg h-[80px] w-[80px] flex items-center justify-center p-1">
            <p className="text-[0.690rem]">
              <span className="font-bold text-gray-900">30 Min</span>{" "}
              <span className="text-gray-500">Delivery</span>
            </p>
          </div>
        </div>

        {/* Suggested Products */}
        {/* <div className="mt-2 w-1rem">
          <h3 className="text-[0.7rem] font-bold text-gray-800 mb-2">
            Suggested Products
          </h3>
          <div
            ref={suggestedProductsRef}
            className="flex gap-4 overflow-hidden pb-2"
            onWheel={handleWheelScrollSuggested} // Handle mouse wheel scroll for suggested products
            style={{ scrollbarWidth: "none" }} // Hide scrollbar for Firefox
          >
            {suggestedProducts.map((item) => (
              <div className="basis-[10rem] shrink-0 ">
                <FrequentBuysProductCard />
              </div>
            ))}
          </div>
        </div> */}

        {/* Scrollable Cart Items */}
        <h3 className="text-[0.755rem]  ml-2 font-bold text-gray-800 mb-2">
          Zoom Supermarket
        </h3>
        <div
          className="flex-grow overflow-y-scroll pl-1 h-[60vh] "
          // onWheel={handleWheelScrollCart} // Handle mouse wheel scroll for cart items
        >
          <div className="">
            {cartItems.map((product) => (
              <div className="mt-4">
                <CartItemStrip item={product} />
              </div>
            ))}
          </div>
        </div>

        {/* Fixed Checkout Button */}
        <div className="absolute bottom-0 left-0 right-0 p-4 bg-gray-100">
          <button
            onClick={handleCheckout}
            className="w-full outline outline-offset-4 outline-[#217229] bg-[#217229] text-white py-3 border-t border-b border-gray-500 rounded-[2rem] text-sm font-bold hover:bg-green-600"
          >
            Checkout
          </button>
        </div>
      </div>
    </div>
  );
}

export default CartWindow;
