import expand from "./assets/expand.svg";
import star from "./assets/star.svg";
import cans from "./assets/cans.svg";
import meals from "./assets/meals.svg";
import rightArrow from "./assets/rightArrow.svg";
import blacktick from "./assets/blacktick.svg";
import appleicon from "./assets/appleicon.svg";
import androidicon from "./assets/androidicon.svg";
import instagram from "./assets/instagram.svg";
import facebook from "./assets/facebook.svg";
import twitter from "./assets/twitter.svg";
import usericon from "./assets/usericon.svg";
import carticon from "./assets/carticon.svg";
import lens from "./assets/lens.svg";
import filter from "./assets/filter.svg";
import openshop from "./assets/openshop.svg";
import opinionCard from "./assets/opinionCard.svg";
import logo from "./assets/logo.svg";
import phone from "./assets/phone.svg";
import close from "./assets/close.svg";
import leftArrow from "./assets/leftArrow.svg";
import like from "./assets/like.svg";
import saveIcon from "./assets/saveIcon.svg";
import bell from "./assets/bell.svg";
import bellLight from "./assets/bellLight.svg";
import aboutLight from "./assets/aboutLight.svg";
import tnc from "./assets/tnc.svg";

import sampleLays from "./assets/dummy/sampleLays.svg";
import agile from "./assets/dummy/agile.svg";
import thegreen from "./assets/dummy/thegreen.svg";
import nexis from "./assets/dummy/nexis.svg";

import fire from "./assets/navbar/fire.svg";
import home from "./assets/navbar/home.svg";
import user from "./assets/navbar/user.svg";
import history from "./assets/navbar/history.svg";
import shop from "./assets/navbar/shop.svg";
import help from "./assets/navbar/help.svg";
import security from "./assets/navbar/security.svg";
import logout from "./assets/navbar/logout.svg";
import suggestedproducts from "./assets/sp.svg";
import download from "./assets/download.svg";

import bag from "./assets/notificationIcons/bag.svg";
import support from "./assets/notificationIcons/support.svg";
import offers from "./assets/notificationIcons/offers.svg";
import blueticks from "./assets/notificationIcons/blueticks.svg";

import saved from "./assets/fav-vendor/saved.svg";
import market from "./assets/fav-vendor/market.svg";

import profilepic from "./assets/ProfileIcons/profilepic.svg";
import edit from "./assets/ProfileIcons/edit.svg";

import your_orders from "./assets/ProfileIcons/your_orders.svg";
import location from "./assets/ProfileIcons/location.svg";
import privacy from "./assets/ProfileIcons/privacy.svg";
import paper from "./assets/ProfileIcons/paper.svg";
import support_profile from "./assets/ProfileIcons/support.svg";
import cash from "./assets/ProfileIcons/cash.svg";

import location_home from "./assets/location_home.png";
import maps from "./assets/maps.png";
import location_office from "./assets/location_office.svg";
import location_others from "./assets/location_others.svg";

const images = {
  expand,
  sampleLays,
  star,
  agile,
  thegreen,
  nexis,
  cans,
  meals,
  rightArrow,
  blacktick,
  appleicon,
  androidicon,
  instagram,
  facebook,
  twitter,
  usericon,
  carticon,
  lens,
  fire,
  home,
  user,
  history,
  shop,
  help,
  security,
  logout,
  filter,
  openshop,
  suggestedproducts,
  opinionCard,
  logo,
  phone,
  close,
  leftArrow,
  bag,
  support,
  offers,
  blueticks,
  saved,
  market,
  like,
  saveIcon,
  bell,
  profilepic,
  edit,
  your_orders,
  location,
  privacy,
  paper,
  support_profile,
  cash,
  bellLight,
  aboutLight,
  tnc,
  download,
  location_home,
  location_office,
  location_others,
  maps,
};

export default images;
