import React from "react";
import images from "../../images";

function CartItemStrip({ item }) {
  return (
    <div className="flex items-center gap-3">
      {/* Image */}
      <div className="bg-white flex items-center justify-center p-3 rounded-lg overflow-hidden">
        <img
          src={images.sampleLays}
          alt={item.name}
          className="w-[40px] scale-105"
        />
      </div>
      {/* Name and qunat */}
      <div className="self-start font-inter font-medium text-[0.875rem]">
        <div>{item.name}</div>
        <div>20g</div>
      </div>
      {/* buttons */}
      <div className="ms-auto bg-[#217229] flex items-center gap-2 text-white font-poppinsMedium px-2 py-1 rounded-lg text-[0.85rem]">
        <button> - </button>
        <button> 0 </button>
        <button> + </button>
      </div>
    </div>
  );
}

export default CartItemStrip;
