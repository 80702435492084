import React, { useState } from "react";

const FilterOptions = () => {
  const [selectedSort, setSelectedSort] = useState("");
  const [selectedRating, setSelectedRating] = useState(
    "Rating (Higher to lower)"
  );
  const [selectedCategory, setSelectedCategory] = useState("");

  const categories = [
    "Grab & Go",
    "Stationery",
    "Pharmacies",
    "Bakeries",
    "Pet Shops",
    "Party Supplies",
    "Ethnic",
    "Self Care",
    "Perfumes",
    "Flower Shops",
    "Fruits",
  ];

  return (
    <div className="p-5 px-8">
      {/* Popular Products Section */}
      <div className="mb-4">
        <h3 className="font-poppinsSemiBold text-[1.15rem] mb-2">
          Popular Products
        </h3>
        <div className="flex flex-col space-y-2">
          <label className="flex items-center space-x-2 font-poppinsMedium text-[0.88rem]">
            <input
              type="radio"
              name="sort"
              value="Price (Higher to lower)"
              checked={selectedSort === "Price (Higher to lower)"}
              onChange={() => setSelectedSort("Price (Higher to lower)")}
              className="form-radio scale-[130%]"
            />
            <span>Price (Higher to lower)</span>
          </label>
          <label className="flex items-center space-x-2 font-poppinsMedium text-[0.88rem]">
            <input
              type="radio"
              name="sort"
              value="Price (Lower to higher)"
              checked={selectedSort === "Price (Lower to higher)"}
              onChange={() => setSelectedSort("Price (Lower to higher)")}
              className="form-radio scale-[130%]"
            />
            <span>Price (Lower to higher)</span>
          </label>
        </div>
      </div>

      {/* Ratings Section */}
      <div className="mb-4">
        <h3 className="font-poppinsSemiBold text-[1rem] mb-2">Ratings</h3>
        <div className="flex flex-col space-y-2">
          <label className="flex items-center space-x-2 font-poppinsMedium text-[0.88rem]">
            <input
              type="radio"
              name="rating"
              value="Rating (Higher to lower)"
              checked={selectedRating === "Rating (Higher to lower)"}
              onChange={() => setSelectedRating("Rating (Higher to lower)")}
              className="form-radio text-blue-600 scale-[130%]"
            />
            <span>Rating (Higher to lower)</span>
          </label>
        </div>
      </div>

      {/* Categories Section */}
      <div>
        <h3 className="font-poppinsSemiBold text-[1rem] mb-2">Categories</h3>
        <div className="flex flex-col space-y-2">
          {categories.map((category) => (
            <label key={category} className="flex items-center space-x-2">
              <input
                type="radio"
                name="category"
                value={category}
                checked={selectedCategory === category}
                onChange={() => setSelectedCategory(category)}
                className="form-radio scale-[130%]"
              />
              <span className="font-poppinsMedium text-[0.88rem]">
                {category}
              </span>
            </label>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FilterOptions;
