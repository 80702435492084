import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

function OtpModal({ setShowOtpModal, to }) {
  const otpInput = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const [focusedEl, setFocusedEl] = useState(null);
  const navigate = useNavigate();
  const handleChange = (e, idx) => {
    const input = e.target;
    const val = input.value;
    otpInput[focusedEl].current.value = val;
    // Moving to next feild
    if (val.length === 1 && idx < otpInput.length - 1) {
      otpInput[idx + 1].current.focus();
    }
  };
  const handleKeyDown = (e, idx) => {
    if (e.key === "Backspace") {
      e.preventDefault();
      if (idx >= 0) {
        otpInput[focusedEl].current.value = "";
        otpInput[idx > 0 ? idx - 1 : 0].current.focus();
      }
    }
  };
  const closeOtpModal = () => {
    setShowOtpModal(false);
  };
  const submitOtp = () => {
    setShowOtpModal(false);
    return navigate(to);
  };
  return (
    <div className="modal flex items-center justify-center ">
      <div className=" bg-white p-0 w-[30%] rounded-xl">
        <div className="flex justify-between px-5 py-3 bg-black text-white font-inter font-medium tracking-wide rounded-t-xl">
          <div className="">Verify Phone</div>
          <button onClick={closeOtpModal} className="">
            {" "}
            X{" "}
          </button>
        </div>
        <div className="bg-white py-3 px-5 mt-3">
          <div className="font-poppinsLight">
            Code sent to{" "}
            <span className="font-poppinsMedium">+91 9876543210</span>{" "}
          </div>
        </div>
        <div className="flex justify-evenly mt-4 mb-3 w-[90%] mx-auto">
          {otpInput.map((inputref, idx) => {
            return (
              <input
                className="border bg-white w-[10%] max-sm:w-[15%] h-[50px] text-center text-[1rem]"
                key={idx}
                maxLength={1}
                minLength={1}
                ref={inputref}
                type="text"
                onChange={(e) => handleChange(e, idx)}
                onKeyDown={(e) => handleKeyDown(e, idx)}
                onFocus={(e) => setFocusedEl(idx)}
              />
            );
          })}
        </div>
        <div className="mt-6 px-5 py-3 flex justify-between items-center text-[1rem] mb-6 max-md:text-[0.8rem]">
          <div className="font-poppinsLight">
            Didn't receive OTP?{" "}
            <span className="font-poppinsMedium cursor-pointer">
              Send Again
            </span>
          </div>
          <div>
            <button
              onClick={submitOtp}
              className="bg-[#217229] py-3 px-5 rounded-3xl text-white font-poppinsMedium tracking-wide"
            >
              Verify
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OtpModal;
