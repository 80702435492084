import { createSlice } from "@reduxjs/toolkit";

// Slice for Product Description Popup

const initialState = {
  show_product_description: false,
  product_id: null,
  product: {},
};

const productDescriptionSlice = createSlice({
  name: "product_description",
  initialState,
  reducers: {
    open_product_description: (state) => {
      state.show_product_description = true;
    },
    close_product_description: (state) => {
      state.show_product_description = false;
    },
  },
});

export const { open_product_description, close_product_description } =
  productDescriptionSlice.actions;

export default productDescriptionSlice.reducer;
