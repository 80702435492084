import React from "react";
import FilterOptions from "../../components/FilterOptions/FilterOptions";
import VendorDetails from "../../components/VendorDetails/VendorDetails";
// import ProductCard from "../../components/ProductCard";
import FrequentBuysProductCard from "../../components/ProductCard/ProductCard";

function Vendor() {
  const products = Array.from({ length: 100 }, (_, index) => index);
  return (
    <div className=" h-full flex">
      {/* Filter Menu to filter products in Supermarket */}
      <div className="border-e-2 border-[#00000029] flex-shrink-0">
        <FilterOptions />
      </div>
      <div className="flex-grow  flex flex-col">
        <div className="mt-5 w-[95%] flex-grow mx-auto flex flex-col ">
          <div className="">
            {/* Vendor rating, service fee etc. Meta data of vendor page */}
            <VendorDetails />
          </div>
          <div className="font-poppinsSemiBold text-[1.15rem] mt-5">
            Popular Products
          </div>
          {/* All the Products of the Vendor/Supermarket */}
          <div
            style={{}}
            className="flex-grow max-h-[68vh] mt-3 gap-y-3 gap-x-1  overflow-y-scroll flex flex-wrap justify-evenly"
          >
            {products.map((product, idx) => {
              return (
                <div className="basis-[220px] border border-[#E6EDF3] rounded-xl">
                  <FrequentBuysProductCard />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Vendor;
