import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showCheckout: false,
};

const checkoutDisplaySlice = createSlice({
  name: "showCheckout",
  initialState,
  reducers: {
    open_checkout: (state) => {
      state.showCheckout = true;
    },
    close_checkout: (state) => {
      state.showCheckout = false;
    },
  },
});

export const { open_checkout, close_checkout } = checkoutDisplaySlice.actions;
export default checkoutDisplaySlice.reducer;
