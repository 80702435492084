import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showCart: false,
};

const displayCartSlice = createSlice({
  name: "showCart",
  initialState,
  reducers: {
    open_cart: (state) => {
      state.showCart = true;
    },
    close_cart: (state) => {
      state.showCart = false;
    },
  },
});

export const { open_cart, close_cart } = displayCartSlice.actions;
export default displayCartSlice.reducer;
