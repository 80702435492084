import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  notifications: [],
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    open_notifications: (state) => {
      state.show = true;
    },
    close_notifications: (state) => {
      state.show = false;
    },
  },
});

export const { open_notifications, close_notifications } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
