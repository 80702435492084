import React from "react";
import images from "../../images";
import CartItemStrip from "../CartItemStrip/CartItemStrip";
import { Home } from "lucide-react";
import { useDispatch } from "react-redux";
import { close_checkout } from "../../redux/checkoutDisplaySlice";
import { open_cart } from "../../redux/cartDisplaySlice";
import toast from "react-hot-toast";

function CheckoutModal({}) {
  const dispatch = useDispatch();
  const cartItems = Array.from({ length: 5 }, (_, idx) => ({
    id: 1,
    name: "Lay's Spanish Tomato Tango",
    size: "20g",
    quantity: 2,
    price: 20,
    image: images.suggestedproducts, // Add proper image path
  }));
  const handlePay = () => {
    dispatch(close_checkout());
    toast.success("Order Succesfull!");
  };

  return (
    <div className="modal flex justify-center items-center ">
      <div className=" w-[600px] rounded-xl overflow-hidden">
        {/* Cart Window */}
        <div
          className="bg-gray-100 h-full shadow-lg p-6 relative transform transition-transform duration-300" // Initially off-screen
        >
          {/* Close Button */}
          <button
            onClick={() => {
              dispatch(close_checkout());
              dispatch(open_cart());
            }}
            className="absolute top-4 right-4 text-gray-600 text-2xl font-bold"
          >
            &times;
          </button>

          {/* Delivery and Timer */}
          <div className="flex items-center mb-2">
            {/* Timer Icon */}
            <span className="text-xl text-gray-600 mr-2">&#9202;</span>
          </div>

          {/* Delivery Estimate */}
          <div className="flex items-center justify-center mb-7">
            <div className="text-center border rounded-full shadow-lg h-[80px] w-[80px] flex items-center justify-center p-1">
              <p className="text-[0.690rem]">
                <span className="font-bold text-gray-900">30 Min</span>{" "}
                <span className="text-gray-500">Delivery</span>
              </p>
            </div>
          </div>

          {/* Scrollable Cart Items */}
          <h3 className="text-[0.755rem]  ml-2 font-bold text-gray-800 mb-2">
            Zoom Supermarket
          </h3>
          <div
            className="flex-grow overflow-y-scroll pl-1 h-[60vh] "
            // onWheel={handleWheelScrollCart} // Handle mouse wheel scroll for cart items
          >
            <div className=" pb-64">
              {cartItems.map((product) => (
                <div className="mt-4">
                  <CartItemStrip item={product} />
                </div>
              ))}
            </div>
          </div>

          {/* Fixed Checkout Button */}
          <div className="absolute bottom-0 left-0 right-0 p- bg-gray-100">
            {/* card  */}
            <div
              style={{
                boxShadow: "0px -5px 24.8px 0px #0000001C",
              }}
              className="p-4 bg-white rounded-xl"
            >
              {/* Address Section */}
              <div className="flex items-start justify-between mb-6 border-b pb-6">
                <div className="flex gap-4">
                  <div className="bg-gray-100 w-[50px] h-[50px] flex justify-center items-center rounded-xl">
                    <img src={images.location_home} className="w-[20px]" />
                  </div>
                  <div>
                    <div className="flex items-center gap-2">
                      <h2 className="text-[1.125rem] font-poppinsMedium">
                        Delivering to Home
                      </h2>
                    </div>
                    <p className="text-gray-600 mt-1 text-[1rem] font-poppinsLight line-clamp-1">
                      Block D / Flat no 101 omaz height, near bay area
                      dskfjsdifiosfisod
                    </p>
                  </div>
                </div>
                <button className="text-green-700 font-medium hover:text-green-800">
                  Change
                </button>
              </div>

              {/* Payment Section */}
              <div className="flex items-center justify-between">
                <div>
                  <div className="text-gray-700 font-medium mb-1">
                    PAY USING CREDIT CARD ▼
                  </div>
                  <div className="font-mono">**** **** **** 5689</div>
                </div>
                <button
                  onClick={handlePay}
                  className="justify-center bg-green-700 text-white px-8 py-3 rounded-full flex items-center gap-2 hover:bg-green-800 transition-colors"
                >
                  <span>₹ 200</span>
                  <span className="text-xl">→</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutModal;
