import React, { useState } from "react";

const VendorDetails = () => {
  const [saved, setSaved] = useState(false);
  return (
    <div className="flex items-center justify-between bg-white ">
      {/* Vendor Name, Location, and Bookmark */}
      <div className="flex items-center space-x-2">
        <h3 className="font-medium font-inter text-[1.25rem]">Zoom Market</h3>
        <span className="text-gray-500 text-[1.25rem]">| Business Bay</span>
        {/* Bookmark Icon */}
        <div
          onClick={() => setSaved(!saved)}
          className="cursor-pointer border border-black p-2 rounded-full"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill={saved ? "black" : "none"}
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6 text-black"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 3v18l5.25-3 5.25 3V3A1.5 1.5 0 0015.75 1.5h-7.5A1.5 1.5 0 006.75 3z"
            />
          </svg>
        </div>
      </div>

      {/* Ratings, Service Fee, Delivery Time, and Minimum Order */}
      <div className="flex items-center space-x-4">
        {/* Rating */}
        <div className="flex items-center space-x-1 border-b border-b-black">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
            className="w-5 h-5 text-yellow-500"
          >
            <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
          </svg>
          <span className="font-poppinsSemiBold text-[1rem]">4.9</span>
        </div>

        {/* Service Fee */}
        <div className="px-3 py-2 text-sm bg-gray-100 rounded-full font-poppinsMedium text-[0.8rem]">
          AED 5 Service Fee
        </div>

        {/* Delivery Time */}
        <div className="px-3 py-2 text-sm bg-gray-100 rounded-full font-poppinsMedium text-[0.8rem]">
          60 Mins
        </div>

        {/* Minimum Order */}
        <div className="px-3 py-2 text-sm bg-gray-100 rounded-full font-poppinsMedium text-[0.8rem]">
          Min. Order AED 40
        </div>
      </div>
    </div>
  );
};

export default VendorDetails;
